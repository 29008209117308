export default{
  init(  ){
    const TOGGLE = document.querySelector( '.iko-btn.iko-btn--toggle' );

    if( TOGGLE ) {
      const ITERATOR = parseInt( document.querySelector( '[data-iterator]' ).getAttribute( 'data-iterator' ) );
      const BOOKS = document.querySelectorAll( '.iko-book-grid>.container>.columns>.column' );
      
      let current = ITERATOR;

      TOGGLE.addEventListener( 'click', e => {
        BOOKS.forEach( ( book, index ) => {
          if( index+1 > ITERATOR && index+1 <= current + ITERATOR ){
            book.classList.remove( 'is-hidden' );

            if( index >= BOOKS.length -1 ){
              TOGGLE.classList.add( 'disabled' );
            }
          }
        } );
        current += ITERATOR;
        e.preventDefault();
      } );
    }
  }
}